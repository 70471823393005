import React from 'react'

import { useRecoilValue } from 'recoil'
import { recoilLang } from '../../../../recoil/app'

import { ListLink } from './ListLink.styled'

const Component = ({ uri, children, ...props }) => {
  const lang = useRecoilValue(recoilLang)
  return <ListLink to={uri} lang={lang} {...props}>
    {children}
  </ListLink>
}

export default Component
