import styled from 'styled-components'

export const List = styled.ol`
margin-top: var(--spacing-12);
margin-bottom: var(--spacing-6);
list-style: none;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
@media (min-width: 768px) {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-between;
}
`

export const Item = styled.li`
position: relative;
padding-left: 0 !important;
@media (min-width: 768px) {
  width: calc(50% - var(--spacing-2));
}
`
