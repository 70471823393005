import { Link } from 'gatsby'
import styled from 'styled-components'

export const ListLink = styled(Link)`
position: relative;
padding: var(--spacing-3) var(--spacing-8) var(--spacing-3) 0;
text-decoration: none;
display: block;
transition: padding 0.3s;
&:after {
  position: absolute;
  top: var(--spacing-3);
  right: 0;
  content: "→";
  transition: right 0.3s;
}
&:hover,
&:focus,
&:active {
  padding-left: var(--spacing-5);
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);
  &:after {
    right: var(--spacing-5);
    color: var(--color-primary-contrast);
  }
}
${p => p.single ? `
margin-bottom: var(--spacing-8);
border-top: 1px solid var(--color-primary);
border-bottom: 1px solid var(--color-primary);
` : ``}
`
