import React from 'react'
import { navigate } from 'gatsby'

import useTranslation from '../../../hooks/useTranslation'

import { BackLink } from './BackLink.styled'

const Component = ({ uri }) => {
  const {translate} = useTranslation()
  const backClickHandler = e => {
    e.preventDefault()
    if (!window.location.hash)
      window.history.back()
    else
      navigate(uri)
  }
  return <BackLink to={uri} onClick={backClickHandler}>← {translate('Back')}</BackLink>
}

export default Component
