import React, { useMemo } from 'react'
import parse from 'html-react-parser'

import { ListLink } from './ListLinks/ListLink/ListLink.styled'

const SectionNextLink = post => {
  const nextSection = useMemo(() => {
    if (post?.sectionLink?.node?.wpChildren?.nodes?.length)
      return post.sectionLink.node.wpChildren.nodes.find(page => page.menuOrder === post.menuOrder + 1)
    return []
  }, [post])
  if (!nextSection)
    return null
  else
    return <ListLink to={nextSection.uri} single={true}>
      {parse(nextSection.title)}
    </ListLink>
}

export default SectionNextLink
