import styled from 'styled-components'

export const List = styled.ul`
margin-bottom: var(--spacing-8);
list-style: none;
> li {
  margin: 0;
  padding-left: 0;
  border-top: 1px solid var(--color-primary);
  &:last-child {
    border-bottom: 1px solid var(--color-primary);
  }
  &:before {
    display: none;
  }
}
`

export const Item = styled.li`
margin: 0;
padding-left: 0 !important;
border-top: 1px solid var(--color-primary);
&:last-child {
  border-bottom: 1px solid var(--color-primary);
}
&:before {
  display: none;
}
`
