import { Link } from 'gatsby'
import styled from 'styled-components'

import { translate } from '../../../../hooks/useTranslation'

export const Panel = ({ number, lang, theme = 'list', showNumbers = true, prefixNumbers = true }) => `
position: relative;
height: 100%;
padding: var(--spacing-4) var(--spacing-4) var(--spacing-20);
border-radius: var(--spacing-2);
text-decoration: none;
display: flex;
transition: background-color 0.3s;
color: var(--color-primary-contrast);
background-color: var(--color-primary);
${showNumbers ? `
&:before {
  // content: "${number < 10 ? `0${number}` : number}";
  content: "${prefixNumbers && number < 10 ? `0${number}` : number}";
  position: absolute;
  bottom: 0;
  left: var(--spacing-4);
  font-size: var(--fontSize-7);
  color: var(--color-secondary);
}
` : ``}
&:after {
  content: "${translate('Read More', lang)}";
  position: absolute;
  bottom: var(--spacing-4);
  right: var(--spacing-4);
  font-size: var(--fontSize-1);
  color: var(--color-primary-contrast);
}
&:hover,
&:focus,
&:active {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  &:before {
    color: var(--color-primary-contrast);
  }
  &:after {
    color: var(--color-primary);
  }
}
${theme === 'panels' ? `
background-color: var(--color-light-blue);
&:before {
  color: var(--color-primary);
}
` : ``}
`

export const PanelLink = styled(Link).withConfig({ shouldForwardProp: p => p !== 'showNumbers' && p !== 'prefixNumbers'})`
${p => Panel(p)}
`
