import React from 'react'
import parse from 'html-react-parser'

import { List, Item } from './PanelLinks.styled'
import PanelLink from './PanelLink/PanelLink'

const PanelLinks = ({ links, theme = 'list', showNumbers = true, prefixNumbers = true }) => {
  return <List>
    {links.map(({ title, uri }, idx) =>
      <Item key={idx}>
        <PanelLink uri={uri} number={idx + 1} theme={theme} showNumbers={showNumbers} prefixNumbers={prefixNumbers}>
          {parse(title)}
        </PanelLink>
      </Item>)}
  </List>
}

export default PanelLinks
