import React, { useMemo } from 'react'

import PanelLinks from './PanelLinks/PanelLinks'
import ListLinks from './ListLinks/ListLinks'

const SectionIndexLinks = ({sectionIndexTemplateFields, sectionIndexLinks}) => {
  const theme = sectionIndexTemplateFields?.sectionIndexTheme
  const showNumbers = sectionIndexTemplateFields?.sectionIndexShowNumbers
  const prefixNumbers = sectionIndexTemplateFields?.sectionIndexPrefixNumbers
  const links = useMemo(() => {
    if (sectionIndexLinks?.nodes?.length)
      return sectionIndexLinks.nodes.sort((a, b) => a.menuOrder > b.menuOrder ? 1 : -1)
    return []
  }, [sectionIndexLinks])
  switch (theme) {
    case 'panels':
      return <PanelLinks links={links} theme={theme} showNumbers={showNumbers} prefixNumbers={prefixNumbers} style={{marginTop: '1rem'}} />
    default:
      return <ListLinks links={links} />
  }
}

export default SectionIndexLinks
