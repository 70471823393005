import { Link } from 'gatsby'
import styled from 'styled-components'

export const BackLink = styled(Link)`
position: absolute;
top: var(--spacing-8);
left: 0;
color: var(--color-secondary);
font-weight: 500;
font-style: italic;
text-decoration: none;
&:hover,
&:focus,
&:active {
  color: var(--color-primary);
  text-decoration: underline;
}
`
