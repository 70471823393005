import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import { LINKS } from '../config/app'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackLink from '../components/links/BackLink/BackLink'
import SectionIndexLinks from '../components/links/SectionIndexLinks'
import SectionNextLink from '../components/links/SectionNextLink'
import SectionNextIndexLink from '../components/links/SectionNextIndexLink'
import { Article, Footer } from '../components/common.styled'

import useFeaturedImage from '../hooks/useFeaturedImage'

const SectionTemplate = ({data: {post}}) => {
  const featuredImage = useFeaturedImage(post)
  return <Layout featuredImage={featuredImage}>
    <Seo title={post.title} />
    <Article
        className='page section'
        itemScope
        itemType='http://schema.org/Article'
      >
        <header>
          <BackLink uri={post.parent.node.uri} />
          <h1 itemProp='headline'>{parse(post.title)}</h1>
        </header>
        <section itemProp='articleBody'>
          {!!post.content && parse(post.content)}
          <SectionIndexLinks {...post} />
          <Footer>
            {LINKS.SECTION.NEXT && <SectionNextLink {...post} />}
            {LINKS.SECTION.NEXT_INDEX && <SectionNextIndexLink {...post} />}
          </Footer>
        </section>
      </Article>
  </Layout>
}

export default SectionTemplate

export const pageQuery = graphql`
  query SectionTemplate(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      menuOrder
      parent: wpParent {
        node {
          uri
          slug
          ... on WpPage {
            menuOrder
            wpChildren {
              nodes {
                ... on WpPage {
                  uri
                  title
                  menuOrder
                }
              }
            }
            parent: wpParent {
              node {
                ... on WpPage {
                  lang: slug
                  wpChildren {
                    nodes {
                      ... on WpPage {
                        uri
                        title
                        menuOrder
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      sectionIndexLinks: wpChildren {
        nodes {
          ... on WpPage {
            title
            uri
            menuOrder
          }
        }
      }
      sectionLink: wpParent {
        node {
          ... on WpPage {
            menuOrder
            wpChildren {
              nodes {
                ... on WpPage {
                  uri
                  title
                  menuOrder
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
