import React from 'react'

import { useRecoilValue } from 'recoil'
import { recoilLang } from '../../../../recoil/app'

import { PanelLink } from './PanelLink.styled'

const Component = ({ uri, children, ...props }) => {
  const lang = useRecoilValue(recoilLang)
  return <PanelLink to={uri} lang={lang} {...props}>
    {children}
  </PanelLink>
}

export default Component
