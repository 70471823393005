import React from 'react'
import parse from 'html-react-parser'

import { List, Item } from './ListLinks.styled'
import ListLink from './ListLink/ListLink'

const ListLinks = ({ links }) => {
  return <List>
    {links.map(({ title, uri }, idx) =>
      <Item key={idx}>
        <ListLink uri={uri}>
          {parse(title)}
        </ListLink>
      </Item>)}
  </List>
}

export default ListLinks
