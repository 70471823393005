import React, { useMemo } from 'react'
import parse from 'html-react-parser'

import PanelLink from './PanelLinks/PanelLink/PanelLink'

const SectionNextIndexLink = post => {
  const nextSection = useMemo(() => {
    if (post?.parent?.node?.parent?.node?.wpChildren?.nodes?.length)
      return post.parent.node.parent.node.wpChildren.nodes.find(page => page.menuOrder === post.parent.node.menuOrder + 1)
    return []
  }, [post])
  if (!nextSection)
    return null
  else
    return <PanelLink uri={nextSection.uri} number={nextSection.menuOrder - 2}>
      {parse(nextSection.title)}
    </PanelLink>
}

export default SectionNextIndexLink
