import styled from 'styled-components'

const header = `
header {
  position: relative;
}
.hasFeaturedImage & {
  header {
    h1 {
      margin-bottom: var(--spacing-32);
      color: var(--color-primary-contrast);
    }
  }
}
`

const section = `
section {
  margin: calc(var(--spacing-4) * -1) calc(var(--spacing-5) * -1);
  padding: var(--spacing-4) var(--spacing-5);
  border-top-right-radius: var(--spacing-2);
  border-top-left-radius: var(--spacing-2);
  background-color: var(--color-background);
  flex-grow: 1;
  > h2:first-child,
  > p:first-child,
  > ul:first-child,
  > ol:first-child {
    margin-top: var(--spacing-4);
  }
}
`

const lists = `
ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  & > li {
    position: relative;
    padding-left: var(--fontSize-4);
    &:before {
      position: absolute;
      top: var(--spacing-1);
      left: 0;
      content: " ";
      width: var(--fontSize-0);
      height: var(--fontSize-0);
      border-radius: 50%;
      background-color: var(--color-primary);
    }
  }
}
.has-blue-background-color ul > li:before,
.has-black-background-color ul > li:before {
  background-color: var(--color-secondary);
}
ol {
  counter-reset: list;
  list-style: none;
  > li {
    counter-increment: list;
    position: relative;
    min-height: var(--spacing-12);
    padding-left: var(--spacing-12);
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(list, decimal-leading-zero);
      font-size: var(--fontSize-4);
      line-height: var(--fontSize-4);
      color: var(--color-secondary);
    }
  }
}
`

const tables = `
.table-wrap {
  width: 100%;
  margin-bottom: var(--spacing-8);
  overflow-x: auto;
}
figure {
  margin: 0 0 var(--spacing-8) 0;
}
.expandable + .table-wrap {
  table {
    tr {
      width: 100%;
    }
    th:first-child {
      width: 20%;
    }
    th:nth-child(2),
    th:nth-child(3) {
      width: 40%;
    }
  }
}
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: var(--spacing-0);
  border-collapse: collapse;
  thead {
    border: 0;
    tr {
      border: 0;
      border-bottom: 1px solid gray;
    }
  }
  tbody {
    tr {
      border: 0;
      border-bottom: 1px solid lightgray;
    }
  }
  tr {
    th {
      padding: var(--spacing-1);
      border: 0;
      color: var(--color-primary);
      vertical-align: top;
      text-align: left;
    }
    td {
      padding: var(--spacing-1);
      border: 0;
      vertical-align: top;
      text-align: left;
    }
  }
  p {
    display: none;
  }
  ul {
    margin: 0;
    padding: 0;
    > li {
      margin: 0;
      padding: 0 0 0 var(--fontSize-2);
      &:before {
        content: "-";
        top: var(--spacing-0);
        background-color: transparent;
      }
    }
  }
}
.has-fixed-3col-layout table tbody tr td {
  width: 33.3333333333%;
}
.is-style-zebra-cols {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.is-style-zebra-cols table {
  max-width: unset;
  thead {
    border: 0;
    tr {
      border: 0;
      border-top: 2px solid var(--color-primary);
      border-bottom: 2px solid var(--color-primary);
      th {
        &:nth-child(2n) {
          background-color: var(--color-gray);
        }
      }
    }
  }
  tbody {
    tr {
      border: 0;
      border-bottom: 2px solid var(--color-primary);
    }
  }
  tr {
    td {
      padding: var(--spacing-4) var(--spacing-4);
      border: 0;
      color: var(--color-primary);
      vertical-align: top;
      text-align: left;
      &:nth-child(1) {
        width: 6rem;
        font-weight: 700;
      }
      &:nth-child(2) {
        width: 6rem;
      }
      &:nth-child(3) {
        width: 6rem;
      }
      &:nth-child(4) {
        width: 6rem;
      }
      &:nth-child(2n) {
        background-color: var(--color-gray);
      }
    }
  }
}
`

const circleGridList = `
.circle-grid-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-between;
  li {
    width: calc(30vw - var(--spacing-4));
    height: calc(30vw - var(--spacing-4));
    @media (min-width: 768px) {
      width: calc(var(--breakpoint-sm) / 4 - var(--spacing-4));
      height: calc(var(--breakpoint-sm) / 4 - var(--spacing-4));
    }
    margin: var(--spacing-4) 0;
    padding: var(--spacing-2);
    border-radius: 50%;
    color: var(--color-primary-contrast);
    background-color: var(--color-primary);
    font-size: var(--fontSize-2);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      display: none;
    }
  }
}
`

const firstLetterList = `
.first-letter-list {
  list-style: none;
  > li {
    position: relative;
    min-height: var(--spacing-12);
    padding-left: var(--spacing-12);
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-letter);
      font-size: var(--fontSize-4);
      line-height: var(--fontSize-4);
      color: var(--color-secondary);
      background-color: transparent;
    }
  }
}
`

const trafficLightList = `
.traffic-light-list {
  strong {
    margin-bottom: var(--spacing-8);
    font-size: var(--fontSize-4);
  }
  .red {
    strong {
      color: var(--color-danger);
    }
  }
  .amber {
    strong {
      color: var(--color-warning);
    }
  }
  .green {
    strong {
      color: var(--color-success);
    }
  }
  > li {
    padding-left: var(--spacing-20);
    &:before {
      width: var(--spacing-12);
      height: var(--spacing-12);
    }
    &.red:before {
      background-color: var(--color-danger);
    }
    &.amber:before {
      background-color: var(--color-warning);
    }
    &.green:before {
      background-color: var(--color-success);
    }
  }
}
`

const dosList = `
.dos-list {
  > li {
    &:before {
      content: "✓";
      top: var(--spacing-0);
      color: var(--color-primary);
      background-color: transparent;
      font-weight: 600;
    }
  }
  &.nested {
    > li {
      position: relative;
      padding-left: var(--fontSize-4);
      &:before {
        position: absolute;
        top: var(--spacing-1);
        left: 0;
        content: " ";
        width: var(--fontSize-0);
        height: var(--fontSize-0);
        border-radius: 50%;
        background-color: var(--color-primary);
      }
    }
    > li li {
      &:before {
        content: "✓";
        top: var(--spacing-0);
        color: var(--color-primary);
        background-color: transparent;
        font-weight: 600;
      }
    }
  }
}
`

const dontsList = `
.donts-list {
  > li {
    &:before {
      content: "☓";
      top: var(--spacing-0);
      color: var(--color-primary);
      background-color: transparent;
      font-weight: 600;
    }
  }
}
`

export const Article = styled.article`
height: calc(100% - var(--spacing-4));
display: flex;
flex-direction: column;
${header}
${section}
${lists}
${circleGridList}
${firstLetterList}
${trafficLightList}
${dosList}
${dontsList}
${tables}
`

export const Footer = styled.footer`
padding-bottom: var(--spacing-8);
`
